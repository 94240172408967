.container {
  --button-wrapper-justify-content: center;

  margin-top: var(--spacing-sides);
  margin-bottom: var(--spacing-bottom);
  padding-inline: var(--spacing-sides);

  @media (width >= 1000px) {
    --button-wrapper-justify-content: start;
  }
}

.inner {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  margin-inline: auto;
  max-width: var(--max-width-revamp-base);

  @media (width >= 1000px) {
    flex-direction: row-reverse;
  }
}

.content {
  @media (width >= 1000px) {
    max-width: 720px; /* magic number */
  }
}

.image-wrap {
  flex: 1;
}

.img {
  display: block;
  margin-inline: auto;
  margin-bottom: var(--spacing-x-loose);
  width: 100%;
  object-fit: contain;

  @media (width >= 1000px) {
    margin-inline: initial;
    margin-bottom: 0;
    max-width: 540px;
  }
}

.cta__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (width >= 768px) {
    flex-direction: row;
  }

  & > * {
    max-width: 180px;
  }
}

html[dir="rtl"] .secondary-cta__icon {
  transform: scaleX(-1);
}
