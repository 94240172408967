/* Minimise Cosmos FOUC */
/** 
 * 1. Cosmos Variable Font optical spacing just so happens to fit exactly in the container,
 *    whereas the default font it 2px wider, so manually adjusting it here.
 */
.one-title {
  font-weight: var(--font-weight-heading);
  font-size: var(--title-x-large-font-size);
  line-height: var(--title-x-large-line-height);
  font-family: var(--font-family-heading);
  letter-spacing: -0.2px; /* 1 */
}

/*.test {
  position: sticky;
  bottom: 0;

  /*  &[data-anchored="true"] {
    position: absolute;
    bottom: 0;
  }
}*/

.container {
  --_margin-bottom: 100px;
  --_shadow-display: none;
  --_border-radius: none;
  /* https://linear-easing-generator.netlify.app/ */
  --spring-easing: linear(
    0,
    0.009,
    0.035 2.1%,
    0.141,
    0.281 6.7%,
    0.723 12.9%,
    0.938 16.7%,
    1.017,
    1.077,
    1.121,
    1.149 24.3%,
    1.159,
    1.163,
    1.161,
    1.154 29.9%,
    1.129 32.8%,
    1.051 39.6%,
    1.017 43.1%,
    0.991,
    0.977 51%,
    0.974 53.8%,
    0.975 57.1%,
    0.997 69.8%,
    1.003 76.9%,
    1.004 83.8%,
    1
  );
  /*  --_product-filter: none;*/

  position: relative;
  /*    margin-top: calc(var(--navbar-height) * -1);*/

  z-index: 1; /* brings it above adjacent panels with solid background, for dropshadow */
  margin-top: calc(var(--navbar-height) * -1);
  background-color: var(
    --cosmos-color-surface-solid-light
  ); /* Assuming next panel has grey bg */
  /*margin-top: var(--spacing-sides);
  margin-bottom: var(--spacing-bottom);
  padding-inline: var(--spacing-sides);*/
  /*  min-height: 400vh;*/
  /*  padding-top: 16px;*/
  /*  min-height: 450vh;*/
  padding-bottom: 100px; /* stick the cards for 100px */

  @media (width >= 1000px) {
    margin-top: calc(var(--navbar-height) * -1);
    padding-top: initial;
    padding-bottom: initial;
    height: 200lvh;
    min-height: initial;
    overflow: clip;
  }

  &[data-rounded-corners="true"] {
    --_border-radius: 16px;
  }

  &[data-shadows="true"] {
    --_shadow-display: block;
  }
}

.inner-pin {
  @media (width >= 1000px) {
    position: sticky;
    top: 0;
  }
}

.inner {
  display: flex;
  /*  display: grid;*/
  position: relative;
  flex-direction: column;
  /*  grid-template-rows: repeat(1, minmax(0, 1fr));*/
  /*  grid-template-rows: 1fr;*/
  /*  grid-template-columns: auto;*/
  /*  height: 100vh;*/

  @media (width >= 1000px) {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    /* grid-template-columns: auto minmax(800px, var(--max-width-revamp-base)) auto; */
    height: initial;
  }
  /*display: flex;
  column-gap: var(--spacing-xx-loose);
  flex-direction: column-reverse;
  margin-inline: auto;
  width: 100%;
  max-width: var(--max-width-revamp-base); /* this is too big */

  /*@media (width >= 1000px) {
    column-gap: var(--spacing-xxx-loose);
    flex-direction: row-reverse;
    align-items: center;
  }*/
}

.one-inner,
.two-inner,
.three-inner {
  /*  background-color: var(--_background-color);*/
  width: 100%;
  /*  outline: 1px solid blue;*/
  /*  max-width: 500px;*/
  max-width: 100%;
  /* border-end-start-radius: 8px;
  border-end-end-radius: 8px;*/

  @media (width >= 1000px) {
    grid-row: 1 / -1;
    grid-column: 2 / 3;
    height: 100lvh;
    overflow: hidden;
    /*border-start-end-radius: 8px;
    border-end-start-radius: initial;
    border-end-end-radius: 8px;*/
  }
}

.shadow {
  display: var(--_shadow-display);
  position: absolute;
  top: 0;
  right: 0;
  opacity: 1;
  z-index: -2;
  transition: opacity 0.25s linear;
  box-shadow:
    16px 0 24px 0 rgba(0, 15, 30, 0.26),
    4px 0 8px 0 rgba(0, 15, 30, 0.22);
  border-start-start-radius: var(--_border-radius);
  border-start-end-radius: var(--_border-radius);
  border-end-start-radius: initial;
  border-end-end-radius: initial;
  /*  background: red;*/
  width: 100%;
  height: 100%;
  pointer-events: none;

  [data-no-shadow="true"] & {
    opacity: 0;
  }

  @media (width >= 1000px) {
    right: initial;
    left: 0;
    border-start-start-radius: initial;
    border-start-end-radius: var(--_border-radius);
    border-end-start-radius: initial;
    border-end-end-radius: var(--_border-radius);
  }
}

.shadow1 {
  /*border-start-start-radius: var(--_border-radius);
  border-start-end-radius: var(--_border-radius);
  border-end-start-radius: var(--_border-radius);
  border-end-end-radius: var(--_border-radius);*/

  border-start-start-radius: initial;
  border-start-end-radius: initial;
  border-end-start-radius: initial;
  border-end-end-radius: initial;

  @media (width >= 1000px) {
    border-start-start-radius: initial;
    border-start-end-radius: var(--_border-radius);
    border-end-start-radius: initial;
    border-end-end-radius: var(--_border-radius);
  }
}

.shadow2 {
  transition: border-radius 0.25s linear;

  .two[mobile-active="true"] & {
    @media (width < 1000px) {
      border-radius: 0;
    }
  }

  @media (width >= 1000px) {
    width: calc(100vw - 116px);
  }
}

.shadow3 {
  transition: border-radius 0.25s linear;

  .three[mobile-active="true"] & {
    @media (width < 1000px) {
      border-radius: 0;
    }
  }

  @media (width >= 1000px) {
    width: calc(100vw - 58px);
  }
}

.shadow4 {
  transition: border-radius 0.25s linear;
  border-start-start-radius: var(--_border-radius);
  border-start-end-radius: var(--_border-radius);
  border-end-start-radius: var(--_border-radius);
  border-end-end-radius: var(--_border-radius);

  .four[mobile-active="true"] & {
    @media (width < 1000px) {
      border-start-start-radius: initial;
      border-start-end-radius: initial;
    }
  }

  @media (width >= 1000px) {
    border-start-start-radius: initial;
    border-start-end-radius: initial;
    border-end-start-radius: initial;
    border-end-end-radius: initial;
  }
}

.one-mobile-offset {
  @media (width >= 1000px) {
    position: relative;
    /*display: grid;
    grid-template-rows: subgrid;
    grid-template-columns: subgrid;*/
    grid-row: 1 / -1;
    /* grid-column: 1 / 3; */
    grid-column: 1 / -1;
    z-index: 4;
    pointer-events: none;
  }
}

.one {
  --_background-color: var(--cosmos-color-surface-solid-light-lighter);
  --_can-shadow: var(--scheme-can-shadow-rgb, var(--can-hero-shadow-color-rgb));
  /*  --_clip-offset: 88px;*/
  display: flex;
  position: relative;
  /*  margin-right: calc(58px * 3);*/
  grid-row: 1 / -1;
  grid-column: 1 / -1;
  align-items: flex-start;
  /*grid-column: 2 / 3;
  grid-row: 1 / -1;*/

  /*  opacity: 0.5;*/
  z-index: 2;
  /*clip-path: inset(
    0 0 calc(100% - (100svh - var(--_clip-offset))) 0 round 16px
  );*/
  /*  transition: box-shadow 0.25s linear;*/
  /*  margin-inline: var(--spacing-normal);*/
  margin-inline: auto;
  margin-bottom: calc(var(--_margin-bottom) + 200px);
  /* 200 is the height of the card preview */
  /*  margin-bottom: var(--_margin-bottom);*/
  /*  box-shadow: var(--cosmos-elevation-2-above);*/
  /*border-start-start-radius: var(--_border-radius);
  border-start-end-radius: var(--_border-radius);
  border-end-start-radius: var(--_border-radius);
  border-end-end-radius: var(--_border-radius);*/
  background-color: var(--_background-color);
  /*  width: calc(100vw - 32px);*/
  width: 100%;
  /* height: calc(100vh - (90px * 3) - (var(--spacing-normal) * 2));*/
  overflow: clip;
  /*position: sticky;
  top: 0;*/
  /*position: sticky;
  bottom: 0;*/
  /*  transform: translateY(calc(100vh - 100%));*/

  /*&[data-no-shadow="true"] {
    box-shadow:
      0 16px 24px 0 #000f1e00,
      0 4px 8px 0 #000f1e00;
  }*/

  &[clip-path="false"] {
    clip-path: none;
  }

  @media (width >= 1000px) {
    /*    display: grid;*/
    /*grid-template-rows: subgrid;
    grid-template-columns: subgrid;*/
    grid-row: 1 / -1;
    grid-column: 1 / -1;
    /*    transition: box-shadow 0.25s linear;*/
    /*    transform: translateX(-174px);*/
    /*    transform: translateX(-600px);*/
    z-index: 4;
    clip-path: initial;
    margin-bottom: initial;
    /*    margin-right: calc(200px * 3);*/
    margin-left: initial;
    /*  box-shadow:
      16px 0 24px 0 rgba(0, 15, 30, 0.26),
      4px 0 8px 0 rgba(0, 15, 30, 0.22);*/
    border-start-start-radius: initial;
    border-start-end-radius: var(--_border-radius);
    border-end-start-radius: initial;
    border-end-end-radius: var(--_border-radius);
    /*    padding-top: initial;*/
    width: calc(100vw - 174px);
    overflow: initial;

    /*&[data-no-shadow="true"] {
      box-shadow:
        16px 0 24px 0 rgba(0, 15, 30, 0),
        4px 0 8px 0 rgba(0, 15, 30, 0);
    }*/
  }
}

/*
.one-mobile-overlay {
  position: absolute;
  top: calc(100svh - 88px);
  left: 0;
  z-index: 1;
  width: 100%;

  @media (width >= 1000px) {
    display: none !important;
  }
}

.one-mobile-overlay-inner {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.one-mobile-overlay1 {
  --_background: var(--scheme-secondary);
  position: relative;
  z-index: 3;
  margin-top: -16px; /* add a little overlap incase of sub pixel rendering *
  box-shadow: var(--cosmos-elevation-2-above);
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  background-color: var(--_background);
  padding-top: 16px;
  width: calc(100vw - 64px);
  height: 44px;
  /*animation: OverlaySlideIn 0.25s ease-out forwards;
  animation-delay: 0.25s;*
  /*height: 28px;*
}

.one-mobile-overlay2 {
  --_background: var(--scheme-secondary);
  position: relative;
  z-index: 2;
  margin-top: -16px;
  box-shadow: var(--cosmos-elevation-2-above);
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  background-color: var(--_background);
  padding-top: 16px;
  width: calc(100vw - 96px);
  height: 40px;
  /*animation: OverlaySlideIn 0.25s ease-out forwards;
  animation-delay: 0.5s;*
  /*height: 24px;*
}

.one-mobile-overlay3 {
  --_background: var(--scheme-secondary);
  position: relative;
  z-index: 1;
  margin-top: -16px;
  box-shadow: var(--cosmos-elevation-2-above);
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  background-color: var(--_background);
  padding-top: 16px;
  width: calc(100vw - 128px);
  height: 36px;
  /*animation: OverlaySlideIn 0.25s ease-out forwards;
  animation-delay: 0.75s;*
  /*  height: 20;*
}
*/

/*@keyframes OverlaySlideIn {
  from {
    top: -88px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}*/

.two {
  --_background-color: var(
    --scheme-secondary,
    var(--cosmos-color-surface-solid-light)
  );
  --_can-shadow: var(--scheme-can-shadow-rgb, var(--can-hero-shadow-color-rgb));
  /*  display: flex;*/
  /*  overflow: clip;*/
  display: flex;
  position: relative;
  grid-row: 1 / -1;
  grid-column: 1 / -1;
  flex-direction: column;
  align-items: flex-start;
  /*position: sticky;
  top: 0;*/
  align-self: flex-start;
  z-index: 3;
  transition: border-radius 0.25s linear;
  /* min-height: 100vh; */
  /*  transition: box-shadow 0.25s linear;*/
  /*  margin-inline: calc(var(--spacing-normal) + 4px);*/
  margin-inline: auto;
  margin-bottom: var(--_margin-bottom);
  /*    box-shadow: var(--cosmos-elevation-2-above);*/
  border-start-start-radius: var(--_border-radius);
  border-start-end-radius: var(--_border-radius);
  /*border-end-start-radius: var(--_border-radius);
  border-end-end-radius: var(--_border-radius);*/
  background-color: var(--_background-color);
  /*  width: calc(100vw - 40px);*/
  width: 100%;
  /*  height: calc(100vh - (90px * 2) - (var(--spacing-normal) * 2));*/
  min-height: 100lvh;
  /* &[data-no-shadow="true"] {
    box-shadow:
      0 16px 24px 0 #000f1e00,
      0 4px 8px 0 #000f1e00;
  }*/

  &[mobile-active="true"] {
    @media (width < 1000px) {
      border-radius: 0;
    }
  }

  @media (width >= 1000px) {
    display: grid;
    grid-template-rows: subgrid;
    grid-template-columns: subgrid;
    grid-row: 1 / -1;
    /* grid-column: 1 / 3; */
    grid-column: 1 / -1;
    align-self: initial;
    z-index: 3;
    margin-bottom: initial;
    /*    transition: box-shadow 0.25s linear;*/
    /*    margin-right: calc(200px * 2);*/
    /*  margin-right: calc(58px * 2);*/
    margin-left: initial;
    /*box-shadow:
      16px 0 24px 0 rgba(0, 15, 30, 0.26),
      4px 0 8px 0 rgba(0, 15, 30, 0.22);*/
    border-start-start-radius: initial;
    border-start-end-radius: var(--_border-radius);
    border-end-start-radius: initial;
    border-end-end-radius: var(--_border-radius);
    background-color: transparent;
    /* transform: translateX(-116px);*/
    /*    width: calc(100vw - 116px);*/
    /*    width: initial;*/
    min-height: initial;
    overflow: initial;
    pointer-events: none;

    /*&[data-no-shadow="true"] {
      box-shadow:
        16px 0 24px 0 rgba(0, 15, 30, 0),
        4px 0 8px 0 rgba(0, 15, 30, 0);
    }*/
  }
}

.three {
  --_background-color: var(
    --scheme-secondary,
    var(--cosmos-color-surface-solid-light)
  );
  --_can-shadow: var(--scheme-can-shadow-rgb, var(--can-hero-shadow-color-rgb));
  /*  overflow: clip;*/
  display: flex;
  position: relative;
  grid-row: 1 / -1;
  grid-column: 1 / -1;
  align-items: flex-start;
  align-self: flex-start;
  z-index: 4;
  /*&[data-no-shadow="true"] {
    box-shadow:
      0 16px 24px 0 #000f1e00,
      0 4px 8px 0 #000f1e00;
  }*/
  transition: border-radius 0.25s linear;
  /*  min-height: 100vh;*/
  /*position: sticky;
  top: 0;*/
  /*  transition: box-shadow 0.25s linear;*/
  /*  margin-inline: calc(var(--spacing-normal) + 8px);*/
  margin-inline: auto;
  /*  width: calc(100vw - 48px);*/
  /*  height: calc(100vh - (90px) - (var(--spacing-normal) * 2));*/
  margin-bottom: var(--_margin-bottom);
  /*  box-shadow: var(--cosmos-elevation-2-above);*/
  border-start-start-radius: var(--_border-radius);
  border-start-end-radius: var(--_border-radius);
  /*border-end-start-radius: var(--_border-radius);
  border-end-end-radius: var(--_border-radius);*/
  background-color: var(--_background-color);
  width: 100%;
  min-height: 100lvh;

  &[mobile-active="true"] {
    @media (width < 1000px) {
      border-radius: 0;
    }
  }

  @media (width >= 1000px) {
    display: grid;
    grid-template-rows: subgrid;
    grid-template-columns: subgrid;
    grid-row: 1 / -1;
    /* grid-column: 1 / 3; */
    grid-column: 1 / -1;
    align-self: initial;
    /*    transform: translateX(-58px);*/
    z-index: 2;
    margin-bottom: initial;
    /*    transition: box-shadow 0.25s linear;*/
    /*    margin-right: calc(200px * 1);*/
    /*  margin-right: calc(58px * 1);*/
    margin-left: initial;
    /*box-shadow:
      16px 0 24px 0 rgba(0, 15, 30, 0.26),
      4px 0 8px 0 rgba(0, 15, 30, 0.22);*/
    border-start-start-radius: initial;
    border-start-end-radius: var(--_border-radius);
    border-end-start-radius: initial;
    border-end-end-radius: var(--_border-radius);
    background-color: transparent;
    width: initial;
    min-height: initial;
    overflow: initial;
    pointer-events: none;

    /*&[data-no-shadow="true"] {
      box-shadow:
        16px 0 24px 0 rgba(0, 15, 30, 0),
        4px 0 8px 0 rgba(0, 15, 30, 0);
    }*/
  }
}

.four {
  --_background-color: var(
    --scheme-secondary,
    var(--cosmos-color-surface-solid-light)
  );
  --_can-shadow: var(--scheme-can-shadow-rgb, var(--can-hero-shadow-color-rgb));
  display: flex; /* stops strange gap at bottom */
  position: relative;
  grid-row: 1 / -1;
  grid-column: 1 / -1;
  /*position: sticky;
  top: 0;*/
  align-self: flex-start;
  z-index: 5;
  transition: border-radius 0.25s linear;
  /*  margin-inline: calc(var(--spacing-normal) + 16px);*/
  margin-inline: auto;
  /*  box-shadow: var(--cosmos-elevation-2-above);*/
  /*  background-color: var(--_background-color);*/
  /*  container-name: hero-banner;*/
  /*  container-type: block-size;*/
  border-start-start-radius: var(--_border-radius);
  border-start-end-radius: var(--_border-radius);
  /*border-end-start-radius: var(--_border-radius);
  border-end-end-radius: var(--_border-radius);*/
  border-end-start-radius: initial;
  border-end-end-radius: initial;
  background-color: var(--_background-color);
  /*  width: calc(100vw - 56px);*/
  width: 100%;
  /*  height: calc(100vh - (var(--spacing-normal) * 2));*/
  /* min-height: 100vh;*/
  min-height: 100lvh;
  overflow: clip;

  &[mobile-active="true"] {
    @media (width < 1000px) {
      border-start-start-radius: 0;
      border-start-end-radius: 0;
    }
  }

  @media (width >= 1000px) {
    display: grid;
    grid-template-rows: subgrid;
    grid-template-columns: subgrid;
    grid-row: 1 / -1;
    grid-column: 1 / -1;
    align-self: initial;
    z-index: 1;
    margin-right: initial;
    margin-left: initial;
    /*    box-shadow: initial;*/
    border-start-start-radius: initial;
    border-start-end-radius: initial;
    border-end-start-radius: initial;
    border-end-end-radius: initial;
    background-color: var(--_background-color);
    width: initial;
    height: initial;
    min-height: initial;
    overflow: initial;
  }
}

.one-inner {
  display: flex;
  position: relative; /* for card overlay */
  justify-content: center;
  align-items: center;
  padding-top: var(--navbar-height);

  /*  transform: translateY(calc((100vh - 100%) * -1));*/

  @media (width >= 1000px) {
    padding-top: initial;
    padding-left: 58px;
    pointer-events: auto;
  }

  @media (width >= 1200px) {
    padding-left: 174px;
  }
}

.content-wrap-one {
  display: flex;
  position: relative;
  flex-shrink: 0; /* We resize the outer container, this avoid wrap */
  flex-direction: column;
  justify-content: initial;
  align-items: center;
  /*  gap: var(--spacing-loose);*/
  /*  width: 100%; */
  /* padding-bottom: 48px; /* height of button */
  justify-self: center;
  margin-inline: auto;
  padding-inline: var(--spacing-x-loose);
  width: 100vw;
  /* max-width: initial; */
  max-width: calc(
    520px + (var(--spacing-x-loose) * 2)
  ); /* Magic number from Figma, should be 513 but FOUC issues on font width */

  @media (width >= 1000px) {
    display: flex;
    position: initial;
    flex-shrink: initial;
    flex-direction: row;
    align-items: center;
    justify-self: initial;
    margin-inline: auto;
    padding-inline: initial;
    width: 100%;
    max-width: 1027px;
  }
}

.content-wrap {
  display: flex;
  position: relative;
  flex-direction: column; /* column-reverse breaks sticky button */
  align-items: center;
  /*  flex-grow: 1;*/
  margin-inline: auto;
  padding-inline: var(--spacing-x-loose);
  /*  gap: var(--spacing-loose);*/
  width: 100%;
  max-width: calc(
    512px + (var(--spacing-x-loose) * 2)
  ); /* Magic number from Figma */

  @media (width >= 1000px) {
    position: initial;
    flex-grow: initial;
    flex-direction: row;
    margin-inline: auto;
    padding-inline: initial;
    max-width: 1027px;
  }

  &[data-visibility="hidden"] {
    visibility: visible;

    @media (width >= 1000px) {
      visibility: hidden;
    }
  }
}

/* do not set position relative  */
.two-inner {
  display: flex;
  /*  position: relative; /* for card overlay */
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100lvh;

  @media (width >= 1000px) {
    flex-direction: row;
    justify-content: initial;
    border-start-start-radius: initial;
    border-start-end-radius: var(--_border-radius);
    border-end-start-radius: initial;
    border-end-end-radius: var(--_border-radius);
    background-color: var(--_background-color);
    padding-right: calc(58px);
    padding-left: 58px;
    width: calc(100vw - 116px);
    min-height: initial;
    pointer-events: auto;
  }

  @media (width >= 1200px) {
    padding-left: calc(58px * 3);
  }
}

.three-inner {
  display: flex;
  /*  position: relative; /* for card overlay */
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100lvh;

  @media (width >= 1000px) {
    flex-direction: row;
    justify-content: initial;
    border-start-start-radius: initial;
    border-start-end-radius: var(--_border-radius);
    border-end-start-radius: initial;
    border-end-end-radius: var(--_border-radius);
    background-color: var(--_background-color);
    padding-right: calc((58px * 2));
    padding-left: 58px;
    width: calc(100vw - 58px);
    min-height: initial;
    pointer-events: auto;
  }

  @media (width >= 1200px) {
    padding-left: calc((58px * 3));
  }
}

/*
.four-inner {
  grid-row: 1 / -1;
  grid-column: 1 / 2;
  outline: 1px solid blue;
  max-width: 500px;

}
*/

.four-inner {
  @media (width >= 1000px) {
    display: grid;
    position: relative;
    /*    position: relative; /* for card overlay */
    grid-template-rows: subgrid;
    grid-template-columns: subgrid;
    grid-row: 1 / -1;
    grid-column: 2 / -2;
    max-width: 100%;
    height: 100lvh;
  }
}

/*
.two-overlay,
.three-overlay,
.four-overlay {
  display: flex;

  display: none;
  position: absolute;
  right: 0;
  bottom: 0;
  /*  overflow: clip;
  opacity: 1;
  transition: opacity 0.25s linear;
  width: 100%;
  height: 90px;

  &[inert] {
    opacity: 0;
  }

  @media (width >= 1000px) {
    display: flex;
    visibility: visible;
    width: initial;
    height: 100%;
  }

  &[data-visibility="hidden"] {
    display: none;
  }
}
*/

.content-one {
  margin-top: var(--spacing-xx-loose);
  width: 100%;

  @media (width >= 1000px) {
    order: 2;
    margin-top: initial;
    max-width: 658px; /* force overflow on small desktop */
  }
}

.content {
  order: 2;
  margin-bottom: 120px; /* button height + padding */
  width: 100%;

  @media (width >= 1000px) {
    order: initial;
    order: 2;
    margin-bottom: 0;
    max-width: 658px; /* force overflow on small desktop */
  }
}

.teaser-basic {
  margin-inline: auto;
  width: 100%;

  @media (width >= 1000px) {
    margin-inline: initial;
    max-width: 658px;
  }
}

.image-container-one {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  margin-block: var(--spacing-xx-loose);
  padding-inline: var(--spacing-loose);

  @media (width >= 1000px) {
    flex-shrink: 0;
    order: 1;
    margin-block: initial;
  }
}

.image-container {
  display: flex;

  justify-content: center;
  order: 1;
  margin-block: var(--spacing-xx-loose);
  padding-inline: var(--spacing-loose);

  @media (width >= 1000px) {
    flex-grow: 1;
    flex-shrink: 0;
    order: 1;
    margin-block: initial;
  }
}

.image {
  display: block;
  transition:
    opacity 0.25s linear,
    transform 2s var(--spring-easing);
  /*  filter: drop-shadow(var(--_product-filter));*/
  /* will-change: transform;*/
  width: 190px;
  height: auto;
  pointer-events: none;

  [data-product-drop-shadows="true"] & {
    filter: drop-shadow(
      var(--can-subtle-shadow-values)
        rgb(var(--_can-shadow) / var(--can-subtle-shadow-alpha))
    );
  }

  @media (width >= 1000px) {
    width: 261px;
    pointer-events: initial;
  }

  /*  &[mobile-visible="false"] {
    @media (width < 1000px) {
      transform: translateY(25%);
      opacity: 0;
    }
  }

  &[mobile-visible="true"] {
    @media (width < 1000px) {
      transform: translateY(0);
      opacity: 1;
    }
  }*/
}

.desktop-cta-container {
  display: none;

  @media (width >= 1000px) {
    display: initial;
  }
}

/**
 * On mobile these buttons stick to the bottom of the screen
 */
.sticky-button-wrapper-one {
  --button-wrapper-margin-top: 0;

  display: flex;
  position: absolute;
  bottom: 0;
  justify-content: center;

  @media (width >= 1000px) {
    display: none;
  }
}

.sticky-button-wrapper {
  --button-wrapper-margin-top: 0;

  display: flex;
  position: absolute;
  bottom: 0;
  justify-content: center;
  order: 3;

  @media (width >= 1000px) {
    display: none;
  }
}

/**
 * 1. Absolute means this takes up 0 height, and doesn't add awkward spacing at the end.
 * 2. Using padding here for GSAP translation reasons 
 */
.sticky-button-wrapper-inner {
  position: absolute; /* 1 */
  bottom: 0;
  padding-bottom: 32px; /* 1 */
}

/*.button-wrapper {
  display: none;

  @media (width >= 1000px) {
    display: flex;
  }
}*/

.teaser-text1-desktop {
  display: none;

  @media (width >= 1000px) {
    display: block; /* initial removed top margin */
  }
}

.teaser-text1-mobile {
  /* margin-top: var(--spacing-xx-loose); */
  margin-bottom: 120px; /* 32px + button height + 40px */

  @media (width >= 1000px) {
    display: none;
  }
}

.image-one {
  display: block;
  transition:
    opacity 0.25s linear,
    transform 2s var(--spring-easing);

  /*  filter: drop-shadow(var(--_product-filter));*/
  width: 190px;
  height: auto;
  pointer-events: none;

  [data-product-drop-shadows="true"] & {
    filter: drop-shadow(
      var(--can-subtle-shadow-values)
        rgb(var(--_can-shadow) / var(--can-subtle-shadow-alpha))
    );
  }
  /*  will-change: transform, filter;*/

  @media (width >= 1000px) {
    /*    transform: translateY(25%);*/
    scale: 1;
    opacity: 0;
    animation: ImageOneScale 1s ease-out forwards;
    animation-delay: 0.5s;
    transition: none;
    width: 310px;
    pointer-events: initial;
  }

  /*  &[mobile-visible="false"] {
    @media (width < 1000px) {
      transform: translateY(25%);
      opacity: 0;
    }
  }

  &[mobile-visible="true"] {
    @media (width < 1000px) {
      transform: translateY(0);
      opacity: 1;
    }
  }*/
}

@keyframes ImageOneScale {
  from {
    scale: 1;
    opacity: 0;
  }
  to {
    scale: calc(261 / 310);
    opacity: 1;
  }
}

.four-rail {
  display: flex; /* stops strange gap at bottom */
  align-self: center;
  /*  opacity: 0.5;*/

  /*  left: calc(100% + ((100cqw - 100%) / 2));*/

  /* width: 100cqw;*/

  /*grid-row: 1 / -1;
  grid-column: 2 / -1;*/
  border-radius: 16px;
  width: 100%;

  @media (width >= 1000px) {
    position: absolute;
    left: 100%;
    align-self: initial;
    width: 100%;
    height: 100%;
  }

  &[data-mobile-visibility="hidden"] {
    visibility: visible;

    @media (width >= 1000px) {
      visibility: visible;
    }
  }
}

.product-rail {
  display: flex;
  /*  justify-content: center;*/
  /*  width: 100vw;*/
  min-height: 100lvh;
  /*  overflow: clip;*/

  /*@media (width >= 1000px) {
    display: initial;
    justify-content: initial;
    width: initial;
    overflow: initial;
  }*/
}

/*.content {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (width >= 1000px) {
    align-items: flex-start;
  }
}

.image {
  display: block;
  margin-inline: auto;
  margin-bottom: var(--spacing-x-loose);
  width: 176px;
  height: auto;

  @media (width >= 1000px) {
    margin-inline: initial;
    margin-bottom: 0;
  }
}*/
