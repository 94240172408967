/* In a effort to reduce scrolljacking issues with GASP we copied the hight from the RB component */
.container {
  display: flex;
  align-items: center;
  background-color: var(--cosmos-color-surface-solid-light);

  min-height: 500px;

  @media (width > 550px) {
    min-height: calc(564px + 80px);
  }

  @media (width >= 650px) {
    min-height: calc(600px + 128px);
  }

  @media (width >= 1000px) {
    min-height: calc(664px + 128px);
  }

  @media (width >= 1200px) {
    min-height: calc(732px + 128px);
  }

  @media (width >= 1600px) {
    min-height: calc(792px + 128px);
  }

  /* 
  Target the first div of the container which is the
  container of the RbcomOccasion widget
  */
  & * {
    flex: 1;
  }
}
